import React from 'react'

export default function Home() {
    return (

        <div>
            <div className='home'>
                <section id="hero" className='home' class="d-flex align-items-center justify-content-center" >
                    <div class="container aos-init aos-animate" data-aos="fade-up">
                        <div class="row justify-content-center aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                            <div class="col-xl-6 col-lg-8"><h1>Touching lives, one patient at a time. Providing all our best<span>.</span>
                            </h1><h2>Because Your Life Matters. Fast, friendly and accurate care for you.</h2></div>
                        </div>
                        <div  className="photo"   style={{height:'600px'}}>
                            <img src="" alt="" />
                        </div>
                    </div>
                </section>
                <section id="about" class="about">
                    <div class="container aos-init aos-animate" data-aos="fade-up">
                        <div class="row"><div class="col-lg-6 order-1 order-lg-2 aos-init aos-animate" data-aos="fade-left" data-aos-delay="100">
                            <img src="https://img.freepik.com/premium-vector/cartoon-cute-young-doctor-waving_70172-2682.jpg" class="img-fluid" alt="" />
                        </div>
                            <div class="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content aos-init aos-animate" data-aos="fade-right" data-aos-delay="100">
                                <h3>Voluptatem dignissimos provident quasi corporis voluptates sit assumenda.</h3>
                                <p class="fst-italic">Observation, Reason, Human Understanding, Courage; these make the physician." “Wear the white coat with dignity and pride—it is an honor and privilege to get to serve the public as a physician.” “Always remember the privilege it is to be a physician.</p>
                                <ul>
                                    <li><i class="ri-check-double-line"></i> “To ensure good health: eat lightly, breathe deeply, live moderately, cultivate cheerfulness, and maintain an interest in life.” - ...</li>
                                    <li><i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperda mastiro dolore eu fugiat nulla pariatur.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="clients" class="clients">
                    <div class="container aos-init aos-animate" data-aos="zoom-in">
                        <div class="clients-slider swiper">
                            <div class="swiper-wrapper align-items-center">
                                <div class="swiper-slide active">
                                    <h1 class="img-fluid">MyHospital-Our Hospital</h1>
                                </div>
                            </div>
                            <div class="swiper-pagination">
                            </div>
                        </div>
                    </div>
                </section>
                <section id="features" class="features">
                    <div class="container aos-init aos-animate" data-aos="fade-up">
                        <div class="table-responsive">
                            <tr>
                                <td>
                                    <div class="container ">
                                        <div class="card text-center m-3">
                                            <div class="card-header bg-info-subtle ">Services
                                            </div>
                                            <div class="card-body">
                                                <h5 class="card-title">Special Services</h5>
                                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                                <a href="#" class="btn btn-primary">Services</a>
                                            </div>
                                            <div class="card-footer text-muted bg-info-light">Updated Today!!
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="container ">
                                        <div class="card text-center m-3">
                                            <div class="card-header bg-info-subtle ">Services
                                            </div>
                                            <div class="card-body">
                                                <h5 class="card-title">Special Services</h5>
                                                <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
                                                <a href="#" class="btn btn-primary">Services</a>
                                            </div>
                                            <div class="card-footer text-muted bg-info-light">Updated Today!!
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </div>
                    </div>
                </section>
            </div>




 
            
        </div>
       


    )
}
