import React from 'react'
import { Link } from 'react-router-dom'

export default function SideBar() {
  return (
    <div><aside id="sidebar" class="sidebar">

    <ul class="sidebar-nav" id="sidebar-nav">

      <li class="nav-item">
        <Link to={'/'}>
          <i class="bi bi-journal-text"><span>Hospital</span></i>
        </Link>
       
      </li> 
      <li class="nav-item">
        <Link class="nav-link "  to='/dash'>
          <i class="bi bi-grid"></i>
          <span>Dashboard</span>
        </Link>
      </li> 

      <li class="nav-item">
        <a class="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
          <i class="bi bi-menu-button-wide"></i><span>Components</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul id="components-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
          <li>
            <Link to='/department'>
              <i class="bi bi-circle"></i><span>Department</span>
            </Link>
          </li>
          <li>
            <Link to='/employee'>
              <i class="bi bi-circle"></i><span>Employee</span>
            </Link>
          </li>
          <li>
            <Link to={'/serviceList'}>
              <i class="bi bi-circle"></i><span>Service</span>
            </Link>
          </li>
          <li>
            <Link to={'/serviceType'}>
              <i class="bi bi-circle"></i><span>ServiceType</span>
            </Link>
          </li>
         
        </ul>
      </li> 


      <li class="nav-item">
        <Link class="nav-link collapsed"  to='/table'>
          <i class="bi bi-layout-text-window-reverse"></i><span>Tables</span><i class="bi bi-chevron-down ms-auto"></i>
        </Link>
       
      </li> 

      <li class="nav-item">
        <a class="nav-link collapsed" data-bs-target="#charts-nav" data-bs-toggle="collapse" href="#">
          <i class="bi bi-bar-chart"></i><span>Charts</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul id="charts-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
          <li>
            <a href="charts-chartjs.html">
              <i class="bi bi-circle"></i><span>Chart.js</span>
            </a>
          </li>
          <li>
            <a href="charts-apexcharts.html">
              <i class="bi bi-circle"></i><span>ApexCharts</span>
            </a>
          </li>
          <li>
            <a href="charts-echarts.html">
              <i class="bi bi-circle"></i><span>ECharts</span>
            </a>
          </li>
        </ul>
      </li> 

      <li class="nav-item">
        <a class="nav-link collapsed" data-bs-target="#icons-nav" data-bs-toggle="collapse" href="#">
          <i class="bi bi-gem"></i><span>Opd</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <ul id="icons-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
          <li>
            <Link to='/addUpdateop/Opd'>
              <i class="bi bi-circle"></i><span>AddOpd</span>
            </Link>
          </li>
          <li>
            <Link to={'/opdList'}>
              <i class="bi bi-circle"></i><span>OpdList</span>
            </Link>
          </li>
          <li>
            <a href="icons-boxicons.html">
              <i class="bi bi-circle"></i><span>Boxicons</span>
            </a>
          </li>
        </ul>
      </li> 

  

      <li class="nav-item">
        <Link class="nav-link collapsed" to='/register'>
          <i class="bi bi-card-list"></i>
          <span>Register</span>
        </Link>
      </li> 

      <li class="nav-item">
        <Link class="nav-link collapsed"  to='/login'>
          <i class="bi bi-box-arrow-in-right"></i>
          <span>Login</span>
        </Link>
      </li> 

      <li class="nav-item">
        <Link class="nav-link collapsed"  to='/404'>
          <i class="bi bi-dash-circle"></i>
          <span>Error 404</span>
        </Link>
      </li> 

      <li class="nav-item">
        <a class="nav-link collapsed" href="pages-blank.html">
          <i class="bi bi-file-earmark"></i>
          <span>Blank</span>
        </a>
      </li> 

    </ul>

  </aside></div>
  )
}
