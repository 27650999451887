import React, { useContext, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { Consumer } from '../ContextApi/Context';
import Footer from './Footer'
import SideBar from './SideBar'
import Topbar from './Topbar'


export default function Layout() {

  const context = useContext(Consumer);
  const navigate = useNavigate();

  useEffect(() => {
    CheckUser();
  }, [])


  const CheckUser = () => {
    if (context.login == null) {
  
      navigate("/login")
    }

  }
  return (
    <div>
      <div class="container-scroller">
        <Topbar />
        <div class="container-fluid page-body-wrapper">
          <SideBar />
          <div class="main" id='main'>
            <div class="">
              <Outlet />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}
