
import './App.css';
import Layout from './Components/Screens/Layout/Layout';
import {  Routes, Route } from 'react-router-dom'
import Login from './Components/Screens/Display/Login';
import { useContext } from 'react';
import { Consumer } from './Components/Screens/ContextApi/Context';
import DashBoard from './Components/Screens/Display/DashBoard';

import Register from './Components/Screens/Display/Register';
import Page404 from './Components/Screens/Display/404Page';
import Profile from './Components/Screens/Layout/Profile';
import Home from './Components/Screens/Layout/Home';
import AddDepartmetn from './Components/Screens/Display/Department/AddDepartmetn';
import Department from './Components/Screens/Display/Department/Department';
import Employee from './Components/Screens/Display/Employee/Employee';
import CreateUpdateEmployee from './Components/Screens/Display/Employee/CreateUpdateEmployee';
import Opd from './Components/Screens/Display/Opd/Opd';
import OpdList from './Components/Screens/Display/Opd/OpdList';
import CreateUpdateService from './Components/Screens/Display/Service/CreateUpdateService';
import ServiceList from './Components/Screens/Display/Service/ServiceList';
import ServiceType from './Components/Screens/Display/ServiceType/ServiceType';
import CreateUpdateServiceType from './Components/Screens/Display/ServiceType/CreateUpdateServiceType';
import Table from './Components/Screens/SimpleTable';


function App() {

  const context = useContext(Consumer)

  if (context.loading) {
    return (
      <h1>
        loading...................
      </h1>
    )
  }
  return (


    <div className="App">
      <Routes>
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/404' element={<Page404 />} />
        <Route path='/' element={<Layout />}> 
          <Route path='/addDepatment/:id?' element={<AddDepartmetn />} />
          <Route path='/' element={<Home />} />
          <Route path='/addUpdateop/Opd/  :id?' element={<Opd />} />
          <Route path='/serviceType' element={<ServiceType />} />
          <Route path='/addUpdate/serviceType/:id?' element={<CreateUpdateServiceType />} />
          <Route path='/addService' element={<CreateUpdateService />} />
          <Route path='/serviceList' element={<ServiceList />} />
          <Route path='/table' elemen={<Table />} />
          <Route path='/opdList' element={<OpdList />} />
          <Route path='/employee' element={<Employee />} />
          <Route path='/addUpdate/employee/:id?' element={<CreateUpdateEmployee />} />
          <Route path='/dash' element={<DashBoard />} />
          <Route path='/department' element={<Department />} />
          <Route path='/profile' element={<Profile />} />

        </Route>
      </Routes>



    </div>
  );
}

export default App;
