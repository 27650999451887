import { Formik } from 'formik'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Consumer } from '../ContextApi/Context'

export default function Login() {

  const context = useContext(Consumer);
  const navigate = useNavigate();

  useEffect(() => {
    {
      CheckUser();
    }
    }
  , [])

  const CheckUser = () => {
    if (context.login==null) {
      navigate("/login");
    }
    else
    {
      navigate("/")
    } 
  }
  return (
    <div>
      <main>
        <div class="container">

          <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                  <div class="d-flex justify-content-center py-4">
                    <a href="index.html" class="logo d-flex align-items-center w-auto">
                      <img src="/assets/img/logo.png" alt="" />
                      <span class="d-none d-lg-block">NiceAdmin</span>
                    </a>
                  </div>

                  <div class="card mb-3">

                    <div class="card-body">

                      <div class="pt-4 pb-2">
                        <h5 class="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                        <p class="text-center small">Enter your username &amp; password to login</p>
                      </div>
                      <Formik

                        initialValues={
                          {
                            userName: "",
                            password: ""
                          }

                        }
                        onSubmit={async(values) => {
                          alert("hello")
                          context.Getlogin(values)

                        }}
                      >
                        {({
                          handleBlur,
                          handleChange,
                          handleSubmit,
                          handleReset,
                          values,
                          isSubmitting,
                        }) => (
                          <form onSubmit={handleSubmit} class="row g-3 needs-validation" novalidate="">

                            <div class="col-12">
                              <label for="yourUsername" class="form-label">Username</label>
                              <div class="input-group has-validation">
                                <span class="input-group-text" id="inputGroupPrepend">@</span>
                                <input type="text" name="userName"
                                  onChange={handleChange}
                                  onSubmit={handleSubmit}
                                  onBlur={handleBlur}
                                  value={values.userName} class="form-control" id="yourUsername" required="" />
                                <div class="invalid-feedback">Please enter your username.</div>
                              </div>
                            </div>

                            <div class="col-12">
                              <label for="yourPassword" class="form-label">Password</label>
                              <input type="password" name="password"
                                onChange={handleChange}
                                onSubmit={handleSubmit}
                                onBlur={handleBlur}
                                value={values.password}

                                class="form-control" id="yourPassword" required="" />
                              <div class="invalid-feedback">Please enter your password!</div>
                            </div>

                            <div class="col-12">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe" />
                                <label class="form-check-label" for="rememberMe">Remember me</label>
                              </div>
                            </div>
                            <div class="col-12">
                              <button class="btn btn-primary w-100"  type="submit">Login</button>
                            </div>
                            <div class="col-12">
                              <p class="small mb-0">Don't have account? <a href="pages-register.html">Create an account</a></p>
                            </div>
                          </form>

                        )}
                      </Formik>

                    </div>
                  </div>

                  <div class="credits">

                  </div>

                </div>
              </div>
            </div>

          </section>

        </div>
      </main>
    </div>
  )
}
