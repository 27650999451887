import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom';
import Comman from '../../../HOC/Comman'

function ServiceList({data}) {
    const [search,setSearch]=useState([]);
    const [filteredData, setFilteredData] = useState(data);
  
    const handleSearch = (value) => {
      const filteredData = data.filter((employee) => {
        return employee.firstName.toLowerCase().includes(value.toLowerCase());
      });
      setFilteredData(filteredData);
    };
    
    useEffect(() => {
      setFilteredData(data);
    }, [data]);
  

    const coloumns=[

        {
            name :'serviceName',
            selector:'serviceName',
            sortable:true
        },
        {
            name :'amount',
            selector:'amount',
            sortable:true
        } ,
        {

            name :'hospitalID',
            selector:'hospitalID',
            sortable:true
        },
           {
            name :'name',
            selector:'name',
            sortable:true
        } ,
           {
            name :'isDisCountable',
            selector:'isDisCountable',
            sortable:true
        } ,
           {
            name :'creterDate',
            selector:'creterDate',
            sortable:true
        },
        {
            name :'validDate',
            selector:'validDate',
            sortable:true
        },
        {
            name: 'Action',
            cell: row => <Link to={`/addService/${row.id}`} className='btn btn-success' >Edit</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
          },

    ]
  return (
    <div>
        <DataTable
        
        columns={coloumns}
        data={filteredData}
        pagination={true} 
        paginationRowsPerPageOptions={[3, 10, 50]}
        highlightOnHover={true}
        fixedHeader={true} 
        fixedHeaderScrollHeight='400px'
        actions={<Link to={'/addService'} className='btn btn-primary'>Add Employee</Link>}
        subHeader
        subHeaderWrap
        subHeaderComponent={
          <>
            <input className='w-25 form-control' type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
            <button onClick={() => handleSearch(search)}>Search</button>
          </>
        }
      
        />
    </div>
  )
}

export default Comman(ServiceList,'/pannel/services','/pannel/services/')