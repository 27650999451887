// // import React from 'react'
// // import { Link } from 'react-router-dom'
// // import Comman from '../../../HOC/Comman'
// //  function Employee({data}) {
// //   return (
// //     <div>
// //     <div className='employee1' style={{overflow:"auto"}}>
// //     <h1>EmployeeList</h1>
// //     <table class="table table-bordered table-inverse table-responsive" >
// //         <thead class="thead-inverse" className='employee'>
// //             <tr>
// //                 <th>FirstName</th>
// //                 <th>LastName</th>
// //                 <th>MobileNumber</th>
// //                 <th>EmailId</th>
// //                 <th>AlternatMolbileNumber</th>
// //                 <th>Gender</th>
// //                 <th>EmployeeTypeName</th>
// //                 <th>HospitalName</th>
// //                 <th>DateOfJoining</th>
// //                 <th>CreatedDate</th>
// //                 <th>CreaterID</th>
// //                 <th>UserName</th>
// //                 <th>Password</th>
// //                 <th>Action</th>




// //             </tr>
// //             </thead>
// //             <tbody>

// //                { 

// //                 data.map((item, index)=>(

// //                 <tr key={index}>
// //                     <td>{item.firstName}</td>
// //                     <td>{item.lastName}</td>
// //                     <td>{item.mobileNumber}</td>
// //                     <td>{item.emailId}</td>
// //                     <td>{item.alternatMolbileNumber}</td>
// //                     <td>{item.gender}</td>
// //                     <td>{item.employeeTypeName}</td>
// //                     <td>{item.hospitalName}</td>
// //                     <td>{item.dateOfJoining}</td>
// //                     <td>{item.createdDate}</td>
// //                     <td>{item.createrID}</td>
// //                     <td>{item.userName}</td>
// //                     <td>{item.password}</td>
// //                     <td><Link to={`/addUpdate/${item.id}`} className='btn btn-success' >Edit</Link></td>
// //                 </tr>
// //                 ))
// //                }
// //             </tbody>
// //     </table>
// //     <Link to={'/addUpdate'}>Got Add Employee</Link>
// //     </div>

// //     </div>
// //   )
// // }




import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Comman from '../../../HOC/Comman';

function Employee({ data, }) {

  const [search,setSearch]=useState([]);
  const [filteredData, setFilteredData] = useState(data);

  const handleSearch = (value) => {
    const filteredData = data.filter((employee) => {
      return employee.firstName.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredData(filteredData);
  };
  
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  // const [data, setData] = useState(data);

  const columns = [
    {
      name: 'S.No',
      selector: 'id',
      sortable: true
    },
    {
      name: 'FirstName',
      selector: 'firstName',
      sortable: true,
    },
    {
      name: 'LastName',
      selector: 'lastName',
      sortable: true,
    },
    {
      name: 'MobileNumber',
      selector: 'mobileNumber',
      sortable: true,
    },
    {
      name: 'EmailId',
      selector: 'emailId',
      sortable: true,
    },
    {
      name: 'AlternatMolbileNumber',
      selector: 'alternatMolbileNumber',
      sortable: true,
    },
    {
      name: 'Gender',
      selector: 'gender',
      sortable: true,
    },
    {
      name: 'EmployeeTypeName',
      selector: 'employeeTypeName',
      sortable: true,
    },
    {
      name: 'HospitalName',
      selector: 'hospitalName',
      sortable: true,
    },
    {
      name: 'DateOfJoining',
      selector: 'dateOfJoining',
      sortable: true,
    },
    {
      name: 'CreatedDate',
      selector: 'createdDate',
      sortable: true,
    },
    {
      name: 'CreaterID',
      selector: 'createrID',
      sortable: true,
    },
    {
      name: 'UserName',
      selector: 'userName',
      sortable: true,
    },
    {
      name: 'Password',
      selector: 'password',
      sortable: true,
    },
    {
      name: 'Action',
      cell: row => <Link to={`/addUpdate/employee/${row.id}`} className='btn btn-success' >Edit</Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <div>
      <div className='employee1'>
        <h1>EmployeeList</h1>
        <div className='back'>
          <DataTable  
            columns={columns}
            data={filteredData}
            pagination={true} 
            paginationRowsPerPageOptions={[3, 10, 50]}
            highlightOnHover={true}
            fixedHeader={true} 
            fixedHeaderScrollHeight='400px'
            actions={<Link to='/addUpdate/employee/' className='btn btn-primary'>Add Employee</Link>}
            subHeader
            subHeaderWrap
            subHeaderComponent={
              <>
                <input className='w-25 form-control' type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
                <button onClick={() => handleSearch(search)}>Search</button>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Comman(Employee, '/pannel/employees/', `/pannel/employees/`);
