import { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { BaseUrl } from "../Screens/Api/BaseUrl";

const Comman = (OrginalComponent, url ,editUrl,add,single) => {
    const NewComponent = () => {
        const [data, setData] = useState([]);
        const navigate=useNavigate();

        const {id}=useParams();
        useEffect(() => {
             
            if(id==null)
                GetData();
        }, [])


        const GetById=async(id)=>{
            // alert("Get by id called");

            const resp=await BaseUrl.get(url+id);
            if(resp.ok)
            {
                alert("get by id calling")
                
            setData(resp.data)   
            } 
            else
            {
                // navigate('/404')
           alert("hello error ")
                // alert(Error)

                // alert(resp.originalError)
            }

        }
        const GetData = async () => {
            const resp = await BaseUrl.get(url)

            if (resp.ok) {

                setData(resp.data)
            }
            else {
                // alert(",mdfngkj")

                 navigate('/404');
                 
                // alert("there is an error ")
            }
        }
        const GetSingle = async () => {
            const resp = await BaseUrl.put(single)

            if (resp.ok) {
                setData(resp.data)
            }
            else {
                // alert(",mdfngkj")
                 navigate('/404');
                // alert("there is an error ")
            }
        }

        const Post= async(data)=>{
            const resp=  await BaseUrl.post(add,data);
            if(resp.ok)
            {
                // alert("ppost is calling")
                setData(resp.data);
                console.log(resp.data)
                navigate("/employee")
                GetData();
             
            }
            else
            {
                alert(resp.originalError)
            //   alert("there is an error in your api callinp process")
            }
        }




          const Delete=async(id)=>{
            const resp=await BaseUrl.delete(url+id)
            if(resp.ok)
            {
                GetData();
                alert("delete")
            }
            
            else
            {
                    // alert("some errir ")

                    navigate("/404")
           }
            }
            const Update =async(data)=>{
                  const resp=await BaseUrl.put(editUrl+id,data)
                  if(resp.ok)
                  {   alert("update")
                     setData(resp.data)
                     console.log('data is ', resp.data)
                     navigate('/department')
                    navigate('/employee')
                      GetData();
                 }
                 else
                 {
                    //  navigate('/404')
                   alert(resp.originalError)  
                 }
                   
            }


        return <OrginalComponent data={data}  single={single} ondelete={Delete}  onpost={Post}   getid={GetById} isEdit={Update}/>
    }
    return NewComponent;
}


export default Comman;



// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { BaseUrl } from "../Screens/Api/BaseUrl";

// const Comman = (OrginalComponent, url) => {
//   const NewComponent = () => {
//     const [data, setData] = useState(null);

//     const navigate=useNavigate();

//     useEffect(() => {
//       getData();
//     }, []);

//     const getData = async () => {
//       try {
//         const resp = await BaseUrl.get(url);
//         if(resp.ok)
//         {
//             setData(resp.data);
//         }
//         else{
//             navigate("/404")
//         }
//       } catch (error) {
//         alert("There is an error");
        
        
//       }
//     };

//     return <OrginalComponent data={data} />;
//   };

//   return NewComponent;
// };

// export default Comman;
