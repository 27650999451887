import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Comman from '../../../HOC/Comman';
  function Department({data,ondelete},) {
    return (
        <>
<Link to='/addDepatment'>Add Department</Link>

        <div className='container mt-5' style={{ paddingLeft: '65px' }}>

            <h1>Department List</h1>
            <table class="table table-bordered table-hover table-responsive">
                <thead class="thead-inverse">
                    <tr>
                        <th> S.No</th>
                        <th>Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data.map((item, index) => (
                            <tr key={index} >
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>
                                    <span>
                                        <Link  class="btn btn-outline-success" to={`/addDepatment/${item.id}`}>Edit</Link>
                                        {/* <button type="button"  class="btn btn-outline-success"  }>Edit</button> */}
                                        </span>
                                   <span><button class="btn btn-outline-danger" onClick={()=>ondelete(item.id)}  type="submit">Delete</button></span>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>

            </table>
        </div>
        </>

    )
}
export default Comman(Department,"/panel/department/",`/panel/department/`)



