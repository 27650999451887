import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Comman from '../../../HOC/Comman'

 function OpdList({data}) {
const [search,setSearch]=useState([]);
const [filteredData,setFilteredData]=useState(data);


 const handleSearch=(value)=>{
    const filter = data.filter((opd)=>{
        return opd.name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredData(filter);
};
useEffect(() => {
  setFilteredData(data)
}, [data])


const coloumns=[


    {
        name :'Name',
        selector:'name',
        sortable:true
    },
    {
        name :'Gender',
        selector:'gender',
        sortable:true
    },
    {
        name :'dob',
        selector:'dob',
        sortable:true
    },
    {
        name :'Doctor',
        selector:'employeeName',
        sortable:true
    },
    {
        name :'matrialStatus',
        selector:'',
        sortable:true
    },
    {
        name :'date',
        selector:'date',
        sortable:true
    },
    {
        name :'mobileNumber',
        selector:'mobileNumber',
        sortable:true
    },
    {
        name :'service',
        selector:'serviceName',
        sortable:true
    },
    {
        name :'Email',
        selector:'emailID',
        sortable:true
    },

    {
        name :'Bp',
        selector:'bp',
        sortable:true
    },
    {
        name :'Address',
        selector:'address',
        sortable:true
    },
    
    {
        name :'disCount',
        selector:'disCount',
        sortable:true
    },
    {
        name :'weight',
        selector:'weight',
        sortable:true
    },
    {
        name :'City',
        selector:'city',
        sortable:true
    },
    {
        name :'Temperature',
        selector:'temperature',
        sortable:true
    },
    {
        name :'Valid up to Date',
        selector:'validUptoDate',
        sortable:true
    },
    {
        name: 'Action',
        cell: row => <Link to={`/addUpdateop/Opd/${row.id}`} className='btn btn-success' >Edit</Link>,
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
      },
 ]
  return (
    <div>
        <h1>Opd List</h1>
        <>
          <DataTable
          
          columns={coloumns}
          data={filteredData}
          pagination={true}
          paginationRowsPerPageOptions={[3, 10, 50]}
          highlightOnHover={true}
          fixedHeader={true} 
          fixedHeaderScrollHeight='250px'
          actions={<Link to={'/addUpdateop/Opd'} className='btn btn-primary'>Add Employee</Link>}
          subHeader
          subHeaderWrap
          subHeaderComponent={
            <>
              <input className='w-25 form-control' type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
              <button onClick={() => handleSearch(search)}>Search</button>
            </>
          }

          
          
          
          
          
          />
            
     \
        
        
        </>
    </div>
  )
}

export default Comman(OpdList,'/pannel/OpdPatients/ ')
