import React, { useContext, useEffect, useState } from 'react'
import Comman from '../../HOC/Comman'
import { ErrorMessage, Formik } from 'formik'
import { Link } from 'react-router-dom';
import { Consumer, Context } from '../ContextApi/Context';
import Login from '../Display/Login';
import { BaseUrl } from '../Api/BaseUrl';
export default function Profile() {

  const [data, setData] = useState([]);
  const { login } = useContext(Consumer);
  useEffect(() => {
    getId(login);
  }, [login])



  const getId = async () => {
    const resp = await BaseUrl.get(`/pannel/employeesLogins/${login}`)
    if (resp.ok) {
      setData(resp.data)
      // console.log('hello data', resp.data)
      // alert("hello i calling properly")
    }
    else {
      alert(resp.originalError)
    }
  }

  const edit = async (values) => {
    const resp = await BaseUrl.put(`/employees/${login}`,values)
    if (resp.ok) {
      setData(resp.data);
      alert("edit is calling");
    }
    else {
      alert(resp.originalError)
    }

  }

  return (
    <div>
      <main id="main" class="main">

        <div class="pagetitle">
          <h1>Profile</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><Link to='/home'>Home</Link></li>
              <li class="breadcrumb-item">Users</li>
              <li class="breadcrumb-item active">Profile</li>
            </ol>
          </nav>
        </div>

        <section class="section profile">
          <div class="row">
            <div class="col-xl-4">

              <div class="card">
                <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">

                  <img src="/assets/img/profile-img.jpg" alt="Profile" class="rounded-circle" />
                  <h2>{data.firstName} </h2>
                  <h3>Web Designer</h3>
                  <div class="social-links mt-2">
                    <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
                    <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
                    <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
                    <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-xl-8">

              <div class="card">
                <div class="card-body pt-3">
                  <ul class="nav nav-tabs nav-tabs-bordered" role="tablist">

                    <li class="nav-item" role="presentation">
                      <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview" aria-selected="true" role="tab">Overview</button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit" aria-selected="false" tabindex="-1" role="tab">Edit Profile</button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-settings" aria-selected="false" tabindex="-1" role="tab">Settings</button>
                    </li>

                    <li class="nav-item" role="presentation">
                      <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password" aria-selected="false" tabindex="-1" role="tab">Change Password</button>
                    </li>

                  </ul>
                  <div class="tab-content pt-2">

                    <div class="tab-pane fade show active profile-overview" id="profile-overview" role="tabpanel">
                      <h5 class="card-title">About</h5>
                      <p class="small fst-italic">Sunt est soluta temporibus accusantium neque nam maiores cumque temporibus. Tempora libero non est unde veniam est qui dolor. Ut sunt iure rerum quae quisquam autem eveniet perspiciatis odit. Fuga sequi sed ea saepe at unde.</p>

                      <h5 class="card-title">Profile Details</h5>

                      <div class="row">
                        <div class="col-lg-3 col-md-4 label ">Full Name</div>
                        <div class="col-lg-9 col-md-8">{data.firstName + " " + data.lastName} </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3 col-md-4 label">Hospital</div>
                        <div class="col-lg-9 col-md-8">{data.hospitalName}</div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3 col-md-4 label">Job</div>
                        <div class="col-lg-9 col-md-8">{data.employeeTypeName}</div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3 col-md-4 label">Country</div>
                        <div class="col-lg-9 col-md-8">India</div>
                      </div>



                      <div class="row">
                        <div class="col-lg-3 col-md-4 label">Phone</div>
                        <div class="col-lg-9 col-md-8">{data.mobileNumber}</div>
                      </div>

                      <div class="row">
                        <div class="col-lg-3 col-md-4 label">Email</div>
                        <div class="col-lg-9 col-md-8">{data.emailId}</div>
                      </div>

                    </div>

                    <div class="tab-pane fade profile-edit pt-3" id="profile-edit" role="tabpanel">


                      <form>
                        <div class="row mb-3">
                          <label for="profileImage" class="col-md-4 col-lg-3 col-form-label">Profile Image</label>
                          <div class="col-md-8 col-lg-9">
                            <img src="/assets/img/profile-img.jpg" alt="Profile" />
                            <div class="pt-2">
                              <a href="#" class="btn btn-primary btn-sm" title="Upload new profile image"><i class="bi bi-upload"></i></a>
                              <a href="#" class="btn btn-danger btn-sm" title="Remove my profile image"><i class="bi bi-trash"></i></a>
                            </div>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="fullName" class="col-md-4 col-lg-3 col-form-label">firstname </label>
                          <div class="col-md-8 col-lg-9">
                            <input name="fullName" type="text" class="form-control" id="fullName" value="Kevin Anderson" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="about" class="col-md-4 col-lg-3 col-form-label">About</label>
                          <div class="col-md-8 col-lg-9">
                            <textarea name="about" class="form-control" id="about" style={{ height: "40px" }}>Sunt est soluta temporibus accusantium neque nam maiores cumque temporibus. Tempora libero non est unde veniam est qui dolor. Ut sunt iure rerum quae quisquam autem eveniet perspiciatis odit. Fuga sequi sed ea saepe at unde.</textarea>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="company" class="col-md-4 col-lg-3 col-form-label">Company</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="company" type="text" class="form-control" id="company" value="Lueilwitz, Wisoky and Leuschke" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="Job" class="col-md-4 col-lg-3 col-form-label">Job</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="job" type="text" class="form-control" id="Job" value="Web Designer" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="Country" class="col-md-4 col-lg-3 col-form-label">Country</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="country" type="text" class="form-control" id="Country" value="USA" />
                          </div>
                        </div>



                        <div class="row mb-3">
                          <label for="Phone" class="col-md-4 col-lg-3 col-form-label">Phone</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="phone" type="text" class="form-control" id="Phone" value="(436) 486-3538 x29071" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="Email" class="col-md-4 col-lg-3 col-form-label">Email</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="email" type="email" class="form-control" id="Email" value="k.anderson@example.com" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="Twitter" class="col-md-4 col-lg-3 col-form-label">Twitter Profile</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="twitter" type="text" class="form-control" id="Twitter" value="https://twitter.com/#" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="Facebook" class="col-md-4 col-lg-3 col-form-label">Facebook Profile</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="facebook" type="text" class="form-control" id="Facebook" value="https://facebook.com/#" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="Instagram" class="col-md-4 col-lg-3 col-form-label">Instagram Profile</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="instagram" type="text" class="form-control" id="Instagram" value="https://instagram.com/#" />
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="Linkedin" class="col-md-4 col-lg-3 col-form-label">Linkedin Profile</label>
                          <div class="col-md-8 col-lg-9">
                            <input name="linkedin" type="text" class="form-control" id="Linkedin" value="https://linkedin.com/#" />
                          </div>
                        </div>

                        <div class="text-center">
                          <button type="submit" class="btn btn-primary">Save Changes</button>
                        </div>
                      </form>

                    </div>

                    <div class="tab-pane fade pt-3" id="profile-settings" role="tabpanel">


                      <form>

                        <div class="row mb-3">
                          <label for="fullName" class="col-md-4 col-lg-3 col-form-label">Email Notifications</label>
                          <div class="col-md-8 col-lg-9">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="changesMade" checked="" />
                              <label class="form-check-label" for="changesMade">
                                Changes made to your account
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="newProducts" checked="" />
                              <label class="form-check-label" for="newProducts">
                                Information on new products and services
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="proOffers" />
                              <label class="form-check-label" for="proOffers">
                                Marketing and promo offers
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="securityNotify" checked="" disabled="" />
                              <label class="form-check-label" for="securityNotify">
                                Security alerts
                              </label>
                            </div>
                          </div>
                        </div>

                        <div class="text-center">
                          <button type="submit" class="btn btn-primary">Save Changes</button>
                        </div>
                      </form>

                    </div>
                    <>

                      <Formik
                        enableReinitialize
                        initialValues={{
                          userName: data.userName,
                          password: data.password,
                        }}
                        onSubmit={(values) => {
                          // console.log("hello edit ",values)

                          // edit(values.password && values.userName);
                          edit(values);

                          console.log("after edit ",values.password,values.userName)
                          // alert("values is edit");

                          // edit(values);
                          // console.log(values)
                        }}
                      >
                        {({ values, handleBlur, handleChange, handleSubmit }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="row mb-3">
                              <label
                                 htmlFor="newPassword"
                                 className="col-md-4 col-lg-3 col-form-label">
                                UserName
                              </label>
                              <div className="col-md-8 col-lg-9">

                                <input type="text"
                                  name='userName'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.userName}
                                  required={'hello this is requires'}
                                />
                              </div>
                            </div>

                            <div className="row mb-3">
                              <label
                                htmlFor="newPassword"
                                className="col-md-4 col-lg-3 col-form-label"
                              >
                                New Password
                              </label>
                              <div className="col-md-8 col-lg-9">
                                <input
                                  type="text"
                                  className="form-control"
                                  name='password'
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  value={values.password}

                                />
                              </div>
                            </div>

                            <div className="text-center">
                              <button type="submit" className="btn btn-primary">
                                Change Password
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>

                    </>


                  </div>

                </div>
              </div>

            </div>
          </div >
        </section >

      </main >
    </div >
  )
}


