import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useNavigate } from 'react-router-dom';
import { Context } from './Components/Screens/ContextApi/Context';
 
// function  Mainapp ()
// {
//   return(
//   <Context>
//   <App />
//   </Context>
//   )
// }

function MainApp()
{
  const navigate=useNavigate();

  return(
    <Context navigate={navigate}>
    <App/>
    </Context>
  )
}
const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <React.StrictMode>
    
  <BrowserRouter>
    <MainApp />
  </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
