import React, { Component, createContext,  } from 'react'
import jwt_decode from'jwt-decode'
import GetLoginApi from '../Api/LoginApi';
import { BaseUrl } from '../Api/BaseUrl';
import Login from '../Display/Login';
const Consumer = createContext();
class Context extends Component {
    constructor(props) {
        super(props)
        this.state = {
            login: null,
            loading:true,
        }


    }
    componentDidMount() {
       this.defaultFunction(); 
    }


    defaultFunction = async () => {
        
        // alert("default function is calling ")
        const token =  localStorage.getItem("token");
        if (token) {
             await this.decodeToken(token);
             
            //  console.log("token",token)

        }
            this.setState({loading:false})


    }
    
    //----------------DECODE TOKEN----------------------


    decodeToken = (token) => { 
        try{
         
        const decode = jwt_decode(token);
         const login = decode.id;

        // this.setState(login.id);
        if(decode.id){
            BaseUrl.setHeader("Authorization","Bearer"+" "+token);
            this.setState({login:decode.id});
        }
        // console.log(decode.id)
        this.setState({login})
        console.log("login is ", login)
     }
    catch{
        localStorage.clear();
    this.props. navigate("/login")
    }

    }
Getlogin= async(data)=>{
    const resp=await GetLoginApi(data);
    if(resp.ok)
    {  
        localStorage.setItem('token',resp.data.token);
        await this.decodeToken(resp.data.token);
        // console.log("tokenuser", resp.data.token)
        this.props.navigate("/")
        return true;
    }
    else{
return  false
}
}
logout =()=>{
    localStorage.clear();
     this.setState({login:null});
     this.props.navigate("/login")
     alert("logout success");
}
   Delete=()=>{
    
   }
    render() {
        return (
            <Consumer.Provider value={{
                ...this.state,
                Getlogin: this.Getlogin,
                logout:this.logout,
                defaultFunction: this.defaultFunction,
                decodeToken:this.decodeToken,
            }}>

                {
                    this.props.children
                }
            </Consumer.Provider>
       )
    }
}
export {
    Context, Consumer
}

 

// import React, { Component, createContext } from 'react';
// import jwt_decode from 'jwt-decode';
// import getLoginApi from '../Api/LoginApi';
 

// const Consumer = createContext();

// class Context extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       login: null,
//       loading: true,
//     };
//   }

//   componentDidMount() {
//     this.defaultFunction();
//   }

//   defaultFunction = async () => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       await this.decodeToken(token);
//     }
//     this.setState({ loading: false });
//   };
  // defaultFunction = async () => {
    //     //     const token = localStorage.getItem('token');
    //     //     if (token) {
    //     //       await this.decodeToken(token);
    //     //     }
    //     //     this.setState({ loading: false });
    //     //   };
        
       // decodeToken = (token) => {
    //     //     const decode = jwt_decode(token);
    //     //     if (decode.id) {
    //     //       this.setState({ login: decode.id });
    //     //     }
    //     //   };


//   decodeToken = (token) => {
//     const decode = jwt_decode(token);
//     if (decode.id) {
//       this.setState({ login: decode.id });
//     }
//   };

//   getLogin = async (data) => {
//     const resp = await getLoginApi(data);
//     if (resp.ok) {
//       localStorage.setItem('token', resp.data.token);
//       await this.decodeToken(resp.data.token);

//       this.props.navigate("/")
//       return true;
//     } else {
//       return false;
//     }
//   };

//   render() {
//     return (
//       <Consumer.Provider
//         value={{
//           ...this.state,
//           getLogin: this.getLogin,
//         }}
//       >
//         {this.props.children}
//       </Consumer.Provider>
//     );
//   }
// }

// export { Context, Consumer };
