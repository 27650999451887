import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom';
import Comman from '../../../HOC/Comman'

function ServiceType({ data }) {

    const [search, setSearch] = useState([]);
    const [filteredData, setFilteredData] = useState(data);

    const handleSearch = (value) => {
        const filteredData = data.filter((serviceType) => {
            return serviceType.name.toLowerCase().includes(value.toLowerCase());
        });
        setFilteredData(filteredData);
    };

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    const coloumns = [

        {
            name: 'name',
            selector: 'name',
            sortable: true
        },

        {
            name: 'isActive',
            selector: 'isActive',
            sortable: true
        },
        {
            name: 'Action',
            cell: row => <Link to={`/addUpdate/serviceType/${row.id}`} className='btn btn-success' >Edit</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ]
    return (
        <div>
            <DataTable
                  columns={coloumns}
                data={filteredData}
                pagination={true}
                paginationRowsPerPageOptions={[3, 10, 50]}
                highlightOnHover={true}
                fixedHeader={true}
                fixedHeaderScrollHeight='400px'
                actions={<Link to='/addUpdate/serviceType/:id?' className='btn btn-primary'>Add ServiceType</Link>}
                subHeader
                subHeaderWrap
                subHeaderComponent={
                    <>
                        <input className='w-25 form-control' type="text" value={search} onChange={(e) => setSearch(e.target.value)} />
                        <button onClick={() => handleSearch(search)}>Search</button>
                    </>
                }
            />
    </div>
    )
}
export default Comman(ServiceType,'/panel/service/types',"panel/service/type/","panel/service/types ")
