import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Comman from '../../../HOC/Comman'
function AddDepartmetn({onpost,getid,data,isEdit}) {
    const {id}=useParams();
    useEffect(()=>{

    getid([]);
    // alert(data.name)
}
 
    ,[])
    return (
        <div className='employee1'>
               
              
               <h1>{!id ? "Add Department" : 'Edit Department'}</h1>



            <div class="form-group">

                <Formik
                enableReinitialize
                    initialValues={
                        {
                            name: !id?'':data.name
                        }
                    }
                    onSubmit={(values) => {
                        const data={
                            name:values.name
                        }
                         {id?isEdit(data): onpost(data) };

                        //  alert("hello post is calling")
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                    }) => (
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name}
                                className='form-control'
                            />
                        <button type="submit" className="form-control  btn btn-primary">Submit</button>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    )
}


export default Comman(AddDepartmetn,'/panel/department/','/panel/department/','/panel/department')