import { ErrorMessage, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Comman from '../../../HOC/Comman'
import { BaseUrl } from '../../Api/BaseUrl';
import { Consumer } from '../../ContextApi/Context';
function CreateUpdateEmployee({ onpost, getid, data, isEdit, single }) {
    const { id } = useParams();
    const context = useContext(Consumer)
    console.log("singleusesingleuse", single)
    const [dataa, setData] = useState([]);

    useEffect(() => {
        if (id) {
            getid(id);
            // alert(data.name)
        }
        if (single) {

            const GetSingle = async () => {
                const resp = await BaseUrl.get('panel/employeeTypes')

                if (resp.ok) {
                    setData(resp.data)
                    console.log("resp.data", resp.data)
                }
                else {
                    alert(",mdfngkj")
                    //  navigate('/404');
                    // alert("there is an error ")
                }
            }
            GetSingle()
        }
        else {
            alert("Add")
        }
    }, [])

    return (


        <div>

            <section class="section">
                <div class="row">
                    <div class="col-lg-12">

                        <div class="card">
                            <div class="card-body">
                                {/* {id != null ? (
                                    <h5 class="card-title">Update Employee</h5>

                                ) : (
                                    <h5 class="card-title">Create Employee</h5>

                                )} */}
                                {
                                    !id ? (

                                        <h5 class="card-title">Create Employee</h5>
                                    ) :
                                        (
                                    <h5 class="card-title"> Update Employee</h5>

                                        )
                                      

                                        

                                }
                                <Formik
                                    enableReinitialize
                                    initialValues={
                                        {
                                            firstName: !data ? '' : data.firstName,
                                            lastName: !data ? '' : data.lastName,
                                            mobileNumber: !data ? '' : data.mobileNumber,
                                            alternatMolbileNumber: !data ? '' : data.alternatMolbileNumber,
                                            emailId: !data ? '' : data.emailId,
                                            gender: !data ? '' : data.gender,
                                            dateOfJoining: !data ? '' : data.dateOfJoining,
                                            dataOfBirth: !data ? '' : data.dataOfBirth,
                                            createdDate: !data ? '' : data.createdDate,
                                            createrID: !data ? context.login : data.createrID,// "context.user",
                                            hospitalID: 1,
                                            loginCode: !data ? '' : data.loginCode,
                                            password: !data ? '' : data.password,
                                            employeeTypeID: !data ? '' : data.employeeTypeID
                                        }
                                    }
                                    onSubmit={(values) => {

                                        const data = {

                                            firstName: values.firstName,
                                            lastName: values.lastName,
                                            mobileNumber: values.mobileNumber,
                                            alternateMobileNo: values.alternateMobileNo,
                                            emailId: values.emailId,
                                            gender: values.gender,
                                            dateOfJoining: values.dateOfJoining,
                                            dataOfBirth: values.dataOfBirth,
                                            createdDate: values.createdDate,
                                            createrID: values.createrID,// "context.user",
                                            hospitalID: values.hospitalID,
                                            loginCode: values.loginCode,
                                            password: values.password,
                                            employeeTypeID: values.employeeTypeID
                                        }

                                        { id ? isEdit(data) : onpost(data) };

                                    }}>
                                    {({

                                        values,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting,
                                    }) => (

                                        <form onSubmit={handleSubmit}>
                                            <div class="row mb-3">
                                                <label for="inputText" class="col-sm-2 col-form-label">First Name</label>
                                                <div class="col-sm-10">
                                                    <input
                                                        name='firstName'
                                                        onChange={handleChange}
                                                        value={values.firstName}
                                                        type="text"
                                                        class="form-control" />
                                                </div>
                                            </div>





                                            <div class="row mb-3">
                                                <label for="inputText" class="col-sm-2 col-form-label">Last Name</label>
                                                <div class="col-sm-10">
                                                    <input
                                                        name='lastName'
                                                        onChange={handleChange}
                                                        value={values.lastName}
                                                        type="text"
                                                        class="form-control" />
                                                </div>
                                            </div>


                                            <div class="row mb-3">
                                                <label for="inputText" class="col-sm-2 col-form-label">Mobile Number</label>
                                                <div class="col-sm-10">
                                                    <input
                                                        name='mobileNumber'
                                                        onChange={handleChange}
                                                        value={values.mobileNumber}
                                                        type="number"
                                                        class="form-control" />
                                                </div>
                                            </div>


                                            <div class="row mb-3">
                                                <label for="inputText" class="col-sm-2 col-form-label"> Alternate Mobile Number</label>
                                                <div class="col-sm-10">
                                                    <input
                                                        name='alternatMolbileNumber'
                                                        onChange={handleChange}
                                                        value={values.alternatMolbileNumber}
                                                        type="number"
                                                        class="form-control" />
                                                </div>
                                            </div>


                                            <div class="row mb-3">
                                                <label for="inputText" class="col-sm-2 col-form-label">Email Id</label>
                                                <div class="col-sm-10">
                                                    <input
                                                        name='emailId'
                                                        onChange={handleChange}
                                                        value={values.emailId}
                                                        type="text"
                                                        class="form-control" />
                                                </div>
                                            </div>


                                            <div class="row mb-3">
                                                <label for="inputText" class="col-sm-2 col-form-label">Gender</label>
                                                <div class="col-sm-10">
                                                    <select name="gender" id="" className='form-control'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}

                                                    >

                                                        <option value="">select employee</option>
                                                        <option value={data.gender}>male</option>
                                                        <option value={data.gender}>female</option>


                                                    </select>
                                                    {/* <input
                                                            name='gender'
                                                            onChange={handleChange}
                                                            value={values.gender}
                                                            type="text"
                                                            class="form-control" /> */}
                                                </div>
                                            </div>




                                            <div class="row mb-3">
                                                <label for="inputText" class="col-sm-2 col-form-label">Date Of Join</label>
                                                <div class="col-sm-10">
                                                    <input
                                                        name='dateOfJoining'
                                                        onChange={handleChange}
                                                        value={values.dateOfJoining}
                                                        type="date"
                                                        class="form-control" />
                                                </div>
                                            </div>


                                            <div class="row mb-3">
                                                <label for="inputText" class="col-sm-2 col-form-label">Date Of Birth</label>
                                                <div class="col-sm-10">
                                                    <input
                                                        name='dataOfBirth'
                                                        onChange={handleChange}
                                                        value={values.dataOfBirth}
                                                        type="date"
                                                        class="form-control" />
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <label for="inputText" class="col-sm-2 col-form-label">User Code</label>
                                                <div class="col-sm-10">
                                                    <input
                                                        name='loginCode'
                                                        onChange={handleChange}
                                                        value={values.loginCode}
                                                        type="text"
                                                        class="form-control" />
                                                </div>
                                            </div>


                                            <div class="row mb-3">
                                                <label for="inputText" class="col-sm-2 col-form-label">Password</label>
                                                <div class="col-sm-10">
                                                    <input
                                                        name='password'
                                                        onChange={handleChange}
                                                        value={values.password}
                                                        type="password"
                                                        class="form-control" />
                                                </div>
                                            </div>

                                            <div class="row mb-3">
                                                <label for="inputText" class="col-sm-2 col-form-label">Employee Type</label>
                                                <div class="col-sm-10">
                                                    {/* 
                                                    <input type="number"
                                                    
                                                    
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    onSubmit={handleSubmit}
                                                    value={values.employeeTypeID}
                                                    /> */}
                                                    <select

                                                        name='employeeTypeID'
                                                        onChange={handleChange}
                                                        value={values.employeeTypeID}
                                                        class="form-control"
                                                    >
                                                        <option value={null}>Select Employee</option>



                                                        {dataa.length > 0 && dataa.map(item => (
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        ))}

                                                    </select>
                                                </div>
                                            </div>



                                            <div class="row mb-3">
                                                <label class="col-sm-2 col-form-label"></label>
                                                <div class="col-sm-10">
                                                    <button type="submit" class="btn btn-primary">Submit Form</button>
                                                </div>
                                            </div>
                                        </form>
                                    )}

                                </Formik>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Comman(CreateUpdateEmployee, '/pannel/employees/', '/pannel/employees/', '/pannel/employees', '/panel/employeeTypes')
