import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import Comman from '../../../HOC/Comman'

 function CreateUpdateServiceType({onpost,getid,data,isEdit}) {

   const {id}=useParams()
    useEffect(()=>{
  if(id)
  {
    getid(id)
  }
    })
  return (
    <>
    {
        id>0 ?
        (
            <h1>Update service type</h1>
        ):
         (
        <h1>CreateUpdateServiceType</h1> )
       
    }
    
    
    <div>
            <Formik
            enableReinitialize
                initialValues={
                    {
                        name: !data ? '':data.name,
                    }
                }
                onSubmit={async (values) => {
                    // await context.addServiceType(values)
                    // alert("Is Submit")
                    !id?onpost():isEdit()
                    }
                }
            >
                {({
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit} >
                        <div>
                            <label className='form-lable'>ServiceTypeName</label>
                            <input type="text"
                            name='name'
                            placeholder='please enter sirvce Type'
                            className='w-25 form-control'
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.name} 
                             />
                        </div>
                        <button type='submit' className='btn btn-primary' >Submit</button>

                        <Link to="/ServiceTypelist">Go to ServiceType List</Link>
                    </form>
                )}



            </Formik>
        </div>
    </>
  )
}
export default Comman(CreateUpdateServiceType,'/panel/service/type/','/panel/service/type/','/panel/service/types')
