import React from 'react'
import { Link } from 'react-router-dom'

export default function Page404() {
  return (
    <div>
      <div class="container">

        <section class="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <h1>401</h1>
          <h2>The page you are looking   ?? For This You Are Not Authorize</h2>
          <h1>thanks </h1>
          <Link class="btn" to='/'>Back to home</Link>
          <img src="assets/img/not-found.svg" class="img-fluid py-5" alt="Page Not Found" />
          <div class="credits">

            Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
          </div>
        </section>

      </div>
    </div>
  )
}
