import { Formik } from 'formik';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import Comman from '../../../HOC/Comman';
import { BaseUrl } from '../../Api/BaseUrl';

 function Opd({onpost,getid, data,isEdit}) {

     const {id}=useParams()

    const[emp,setEmp]=useState([]);
    const[service,setService]=useState([]);



    useEffect(() => {
        if(id)
        {

            getid(id)
        }
        else
        {
            alert("Add")
        }
        // employeelist();
        // Getservice();
    
      }
    , [])
    useEffect(() => {
        employeelist();
        Getservice();
    }, [])
    


    const Getservice=async()=>{
        const resp=await  BaseUrl.get("/pannel/services");
        if(resp.data)
        {
            setService(resp.data)
        }
    }

    const employeelist=async()=>{
    const resp=await BaseUrl.get("/pannel/employees");
    if(resp.ok){
   setEmp(resp.data);
   console.log("employee data:=",resp.data)
    }
    else
    {
        alert(resp.originalError)
    }
    }
    
  return (
    <div className='opd'>
        {  id!=null?(

                <h1> OPD Update FORM </h1>
            ):(
                <h1> OPD  FORM </h1>
            )

        }
    <Formik
    enableReinitialize
        initialValues={
            {
                name:!data? "":data.name,
                date: !data? "":data.date,
                validUptoDate: !data?"":data.validUptoDate,
                employeeID:!data? "":data.employeeID,
                serviceID: !data? "":data.serviceID,
                opertorID:!data? "":data.opertorID,// context.userid,
                description: !data? "":data.description,
                weight: !data? "":data.weight,
                bp: !data? "":data.bp,
                temperature: !data? "":data.temperature,
                amount: !data? "":data.amount,
                disCount:!data? "yes":data.disCount,
                gender: !data? "":data.gender,
                dob: !data? "":data.dob,
                matrialStatus: !data? "":data.matrialStatus,
                mobileNumber: !data? "":data.mobileNumber,
                emailID: !data? "":data.emailID,
                address: !data? "":data.address,
                city:!data? "":data.city,
            }

        }
        onSubmit={(values) => {
        //    await context.AddOpd(vvaluesalues)
        const data={
            name:values.name,
            date: values.date,
            validUptoDate:values.validUptoDate,
            employeeID:values.employeeID,
            serviceID: values.serviceID,
            opertorID:values.opertorID,// context.userid,
            Description: values.description,
            weight: values.weight,
            bp: values.bp,
            temperature: values.temperature,
            amount: values.amount,
            disCount:values.disCount,
            gender: values.gender,
            dob: values.dob,
            matrialStatus: values.matrialStatus,
            mobileNumber: values.mobileNumber,
            emailID: values.emailID,
            address: values.address, 
            city:values.city,
        }
        { id ? isEdit(data, alert("hello edit is calling")) : onpost(data) };
        

        // console.log("Umesh1");
        // console.log(data,"Umesh")
        // onpost(data);
        // console.log("Umesh2");
           
        }}>
        {({
            values,
            handleBlur,
            handleChange,
            handleSubmit, 
            isSubmitting,

        }) => (
            <form onSubmit={handleSubmit}>
                <>
                    <div class="container">



                        <div class="row">
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="firstName"> Name</label>
                                    <input type="text" id="firstName" class="form-control form-control-lg"
                                        name='name'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.name}
                                    />
                                </div>

                            </div>
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="lastName">Gender</label>
                                    <input type="text" id="lastName" class="form-control form-control-lg"
                                        name='gender'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.gender}
                                    />
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="firstName"> Dob</label>
                                    <input type="date" id="firstName" class="form-control form-control-lg"
                                        name='dob'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.dob}
                                    />
                                </div>

                            </div>
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="lastName">Doctor </label>



                                    {<select type='number' id="lastName" class="form-control form-control-lg"
                                        name='employeeID'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.employeeID}
                                    >
                                        <option value="">Select Doctor Name</option>
                                        {
                                            emp.map((item) => (
                                                <option value={item.id}>{item.firstName+" "+item.lastName}</option>

                                            ))


                                        }
                                    </select>}
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="firstName"> MatrialStatus</label>
                                    <input type="text" id="firstName" class="form-control form-control-lg"
                                        name='matrialStatus'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.matrialStatus}
                                    />
                                </div>

                            </div>
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="lastName">Created Date</label>
                                    <input type="date" id="lastName" class="form-control form-control-lg"
                                        name='date'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.date}
                                    />
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="firstName"> MobileNumber</label>
                                    <input type="number" id="firstName" class="form-control form-control-lg"
                                        name='mobileNumber'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.mobileNumber}
                                    />
                                </div>

                            </div>
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="lastName">Service</label>
                                    <select  id="lastName" class="form-control form-control-lg"
                                        name='serviceID'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.serviceID}  
                                    >
                                        <option value="">Select Service</option>
                                        {
                                           service.map((item) => (

                                                <option value={item.id}>{item.name}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="firstName"> Email</label>
                                    <input type="text" id="firstName" class="form-control form-control-lg"
                                        name='emailID'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.emailID}
                                    />
                                </div>

                            </div>
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="lastName">Bp</label>
                                    <input type="number" id="lastName" class="form-control form-control-lg"
                                        name='bp'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.bp}
                                    />
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="firstName"> Address</label>
                                    <input type="text" id="firstName" class="form-control form-control-lg"
                                        name='address'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.address}
                                    />
                                </div>

                            </div>
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="lastName">Weight</label>
                                    <input type="text" id="lastName" class="form-control form-control-lg"
                                        name='weight'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.weight}
                                    />
                                </div>


                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="firstName"> City</label>
                                    <input type="text" id="firstName" class="form-control form-control-lg"
                                        name='city'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.city}
                                    />
                                </div>

                            </div>
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="lastName">Temperature</label>
                                    <input type="text" id="lastName" class="form-control form-control-lg"
                                        name='temperature'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.temperature}
                                    />
                                </div>

                            </div>
                        </div>
                      
                       <div class="row">
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="firstName"> ISDiscountable</label>
                                    <input type="text" id="firstName" class="form-control form-control-lg"
                                        name='disCount'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.disCount}
                                    />
                                </div>

                            </div>
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="lastName">Description</label>
                                    <input type="text" id="lastName" class="form-control form-control-lg"
                                        name='description'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.description}
                                    />
                                </div>

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="firstName"> Valid up to Date</label>
                                    <input type="date" id="firstName" class="form-control form-control-lg"
                                        name='validUptoDate'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.validUptoDate}
                                    />
                                </div>
                                <div class="form-outline">
                                    <label class="form-label" for="firstName">  CreaterId</label>
                                    <input  id="firstName" class="form-control form-control-lg"
                                        name='opertorID'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.opertorID}
                                    />
                                </div>

                            </div>
                            <div class="col-md-6 mb-4">

                                <div class="form-outline">
                                    <label class="form-label" for="lastName">Amount</label>
                                    <input type="number" id="lastName" class="form-control form-control-lg"
                                        name='amount'
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.amount}
                                    />
                                </div>

                            </div>
                            
                                <button type='submit' className='btn btn-primary'>Submit</button>
                        </div>
                    </div>

                    <Link to="/opd/opdlist">Got to Opd list</Link>

                </>
            </form>
        )



        }

    </Formik>
</div>

  )
}

export default Comman(Opd,'/pannel/opdPatients/','/pannel/opd/','/pannel/opdPatients')