 import {create} from'apisauce'
const BaseUrl= create(
    {
        baseURL:"https://project23.kmaschool.com/api",

    }  
) 
export  { BaseUrl }


 



// BaseUrl.js
// import { create } from 'apisauce';

// const BaseUrl = create({
//   baseURL: 'https://localhost:7053/api/',
// });

// export default BaseUrl;
