// import { BaseUrl } from "./BaseUrl"

import { BaseUrl } from "./BaseUrl";

// const GetLoginApi=(data)=>{  BaseUrl.post("login/checklogin",data)}
const GetLoginApi =  (data) =>  BaseUrl.post('/login/checklogin', data);
  
  
export default GetLoginApi;


// import BaseUrl from './BaseUrl';

// const getLoginApi = (data) => BaseUrl.post('login/checklogin', data);

// export default getLoginApi;
